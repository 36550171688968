<template>
  <div class="purchase-method-detail-base-info">
    <div class="info-block" v-if="data.assets">
      <div class="info-item">
        <div class="label">资产编码：</div>
        <div class="value">{{data.assets.code}}</div>
      </div>
      <div class="info-item">
        <div class="label">资产名称：</div>
        <div class="value">{{data.assets.name}}</div>
      </div>
      <div class="info-item">
        <div class="label">使用机构：</div>
        <div class="value">{{data.assets.useOrgName}}</div>
      </div>
      <div class="info-item">
        <div class="label">管理机构：</div>
        <div class="value">{{data.assets.manageOrgName}}</div>
      </div>
      <div class="info-item">
        <div class="label">价值：</div>
        <div class="value">{{data.assets.jz}}元</div>
      </div>
    </div>
    <div class="info-block" v-else>
      <div class="info-item">
        <div class="label">资产名称：</div>
        <div class="value">{{data.assetsName}}</div>
      </div>
      <div class="info-item">
        <div class="label">价值：</div>
        <div class="value">{{data.jz}}元</div>
      </div>
    </div>
    <div class="base-info info-block">
      <div class="info-item">
        <div class="label">编码：</div>
        <div class="value">WXSQ{{data.id}}</div>
      </div>
      <div class="info-item">
        <div class="label">申请科室：</div>
        <div class="value">{{data.applyOrgName}}</div>
      </div>
      <div class="info-item">
        <div class="label">申请人：</div>
        <div class="value">{{data.userName}}</div>
      </div>
      <div class="info-item">
        <div class="label">预估维修金额：</div>
        <div class="value">{{data.repairJe}}</div>
      </div>
      <div class="info-item">
        <div class="label">维修厂家：</div>
        <div class="value">{{data.repairCmp}}</div>
      </div>
    </div>
    <div class="base-info info-block">
      <div class="info-item">
        <div class="label">关联预算：</div>
        <div class="value">{{data.budgetDetail ? data.budgetDetail.code + ' ' + data.budgetDetail.goodsName : '无'}}</div>
      </div>
    </div>
    <div class="info-block">
      <div class="info-item">
        <div class="label">申请理由：</div>
        <div class="value">{{data.reason}}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    data: { type: Object, defualt: () => null }
  },
  computed: {
    purchaseBatchDetailList () {
      return this.data && this.data.purchaseBatchDetailList ? this.data.purchaseBatchDetailList : []
    }
  }
}
</script>

<style lang="less" scoped>
.purchase-method-detail-base-info {
  color: #515a6e;
  .info-block {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }
  .detail-info {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #e8eaec;
  }
  .info-item {
    display: flex;
    margin-right: 50px;
    margin-bottom: 5px;
    .label {
      margin-right: 0px;
      white-space: nowrap;
    }
  }
}
</style>