<template>
  <fm-modal title="提交" v-model="modal" width="600px" v-if="modal">
    <fm-form label-align="left">
      <fm-form-item label="职能科室" v-if="updateKeys && updateKeys.split(',').includes('busOrgId')">
        <fm-select absolute filterable :clearable="true" v-model="formData.busOrgId">
          <fm-option v-for="item in orgList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="分管领导" v-if="updateKeys && updateKeys.split(',').includes('leadUserId')">
        <fm-select absolute filterable :clearable="true" v-model="formData.leadUserId">
          <fm-option v-for="item in userList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="院长" v-if="updateKeys && updateKeys.split(',').includes('deanUserId')">
        <fm-select absolute filterable :clearable="true" v-model="formData.deanUserId">
          <fm-option v-for="item in userList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
    </fm-form>
    <div class="model-btn">
      <fm-btn style="margin-right: 30px;" @click="formSubmit">提交</fm-btn>
      <fm-btn @click="modal = false">取消</fm-btn>
    </div>
  </fm-modal>
</template>

<script>
export default {
  props: {
    updateKeys: { type: String, defualt: null }
  },
  data () {
    return {
      formData: {},
      modal: false
    }
  },
  computed: {
    orgList () {
      return this.$store.getters.orgList
    },
    userList () {
      return this.$store.getters.userList
    }
  },
  methods: {
    async formSubmit () {
      let pass = true
      this.updateKeys.split(',').forEach(v => {
        if (!this.formData[v]) {
          pass = false
        }
      })
      if (!pass) {
        this.$notify({
          title: '系统提示',
          message: '请检查表单',
          type: 'info'
        })
      } else {
        this.$emit('submit', this.formData)
        this.modal = false
      }
    }
  }
}
</script>

<style scoped lang="less">
.model-btn {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>